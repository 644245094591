import forEach from 'lodash-es/forEach';
import set from 'lodash-es/set';
import some from 'lodash-es/some';
import sumBy from 'lodash-es/sumBy';

import { Component } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Hub, HubRegion } from '../../entities/hubs-regions';
import { MarketingCampaignHelper } from '../../helpers/marketing-campaign-helper';
import { AppTitleService } from '../../services/app-title.service';
import { CodeListsService } from '../../services/code-lists.service';
import { ModalService } from '../../services/modal.service';
import { PageLoadingService } from '../../services/page-loading.service';
import {
    MarketingCampaign, MarketingCampaignOrdinal, MarketingCampaignType,
    ProductInformationApiService, UserLicenseOptions
} from '../../services/product-information-api.service';
import { Page } from '../page';
import addEditMarketingCampaignDialogTemplate from './add-edit-marketing-campaign-dialog.hbs';
import addEditMarketingCampaignEmailTemplate from './add-edit-marketing-campaign-email.hbs';
import addEditMarketingCampaignHubTemplate from './add-edit-marketing-campaign-hub.hbs';
import importMarketingCampaignEmailsTemplate from './import-marketing-campaign-emails.hbs';
import searchMarketingCampaignsFormTemplate from './search-marketing-campaigns-form.hbs';
import viewMarketingCampaignDialogTemplate from './view-marketing-campaign-dialog.hbs';

interface RegionLanguage {
    Id: number;
    Name: string;
    LcId: number;
    RegionId: number;
    DefaultForRegion: boolean;
}

interface MarketingCampaignRegion {
    Id: number;
    Name: string;
    HubId: number;
    RegionLanguages: MarketingCampaignRegionLanguage[];
    Index: number;
    Url: string;
    Link: string;
    Enabled: string;
    Title: string;
    Description: string;
    InAppPlacement : boolean;
}

interface MarketingCampaignRegionLanguage {
    HubId: number;
    RegionId: number;
    Index: number;
    LcId: number;
    RegionLanguage: string;
    Url: string;
    Link: string;
    Enabled: string;
    Title: string;
    Description: string;
    InAppPlacement : boolean;
}

interface UrlPreview {
    Name: string;
    Url: string;
}

@Component({
    selector: 'app-marketing-campaign',
    templateUrl: './marketing-campaign.component.html',
    styleUrls: ['./marketing-campaign.component.css']
})
export class MarketingCampaignComponent extends Page {
    pageTitle = $localize`:app-title marketing campaign|Marketing campaign application title:Marketing campaigns`;

    private hubRegionCodeList: HubRegion[] = [];
    private hubCodeList: Hub[] = [];
    private regionLanguageCodeList: RegionLanguage[] = [];
    private marketingCampaignHelper: MarketingCampaignHelper;
    private $importMarketingCampaignEmailsExcelFile: JQuery<HTMLElement>;
    environment = environment;
    private inAppPlacement : boolean;

    constructor(
        appTitleService: AppTitleService,
        pageLoadingService: PageLoadingService,
        private productInformationApiService: ProductInformationApiService,
        private modalService: ModalService,
        private codeListsService: CodeListsService
    ) {
        super(appTitleService, pageLoadingService);

        this.marketingCampaignHelper = new MarketingCampaignHelper();
    }

    async ngOnInit() {
        super.ngOnInit();

        this.$importMarketingCampaignEmailsExcelFile = $('#importMarketingCampaignEmailsExcelFile');

        this.initGrid();
        await this.loadData();
    }

    private initGrid() {
        $('#marketingCampaignGrid').jsGrid({
            width: '100%',
            height: 'auto',
            sorting: true,
            paging: true,
            pageSize: 10,
            fields: [
                { name: 'MarketingCampaignId', type: 'number', title: 'Ranking', align: 'left' },
                { name: 'Name', type: 'text', width: 150, title: 'Name', align: 'left' },
                {
                    name: 'IsActive', type: 'text', title: 'Status', align: 'left', itemTemplate: (value: boolean, item: MarketingCampaign) => value ? 'Active' : 'Inactive'
                },
                {
                    name: 'CampaignType', type: 'text', title: 'Type', align: 'left', itemTemplate: (value: MarketingCampaignType, item: MarketingCampaign) => MarketingCampaignType[value]
                },
                {
                    name: 'InAppPlacement', type: 'text', title: 'Placement', align: 'left', itemTemplate: (value: boolean, _item: MarketingCampaign) => value ? 'In App' : 'Pop-up'
                },
                {
                    type: 'text', title: 'Hubs', align: 'left', itemTemplate: (value: void, item: MarketingCampaign) => item.Hubs.map(hub => this.hubCodeList.find(cl => cl.Id == hub.HubId).Name).join(' - ')
                },
                {
                    type: 'text', title: 'Regions', align: 'left', itemTemplate: (value: void, item: MarketingCampaign) => {
                        const itemRegions = item.Hubs.flatMap(hub => hub.Regions)
                            .filter(region => region.Enabled || region.RegionLanguages.some(regionLanguage => regionLanguage.Enabled));

                        const names = itemRegions
                            .map(region => this.hubRegionCodeList.find(cl => cl.Id == region.RegionId).Name);

                        return names.join(' - ');
                    }
                },
                {
                    type: 'text', title: 'Postal Codes', align: 'left', itemTemplate: (value: void, item: MarketingCampaign) => {
                        let codes: string[];

                        if (some(item.PostalCodes)) {
                            codes = item.PostalCodes.map(name => {
                                // shorten name if larger than 6 characters, e.g. SI...01
                                const postalCode = name.length > 6 ? name.slice(0, 2) + '&hellip;' + (name.slice(name.length - 2, name.length)) : name;
                                return postalCode;
                            });
                        }

                        return codes?.join(', ');
                    }
                },
                {
                    name: 'ForNewRegisteredUser', type: 'text', title: 'New users', align: 'left',
                    itemTemplate: (value: boolean, item: MarketingCampaign) => value ? 'True' : 'False'
                },
                {
                    name: 'UserLicenseOptions', type: 'text', title: 'User license options', align: 'left',
                    itemTemplate: (value: UserLicenseOptions, item: MarketingCampaign) => {
                        let result = '';
                        if (typeof value !== 'undefined') {
                            result = Object.keys(UserLicenseOptions).filter((userLicenseOption) => {
                                return (Number(userLicenseOption) & item.UserLicenseOptions) == Number(userLicenseOption);
                            }).map((userLicenseOption) => {
                                return UserLicenseOptions[userLicenseOption];
                            }).join();
                        }
                        if (result.length == 0) {
                            result = 'All'
                        }

                        return result;
                    }
                },
                {
                    name: 'Ordinal', type: 'text', title: 'Priority', align: 'left',
                    itemTemplate: (value: MarketingCampaignOrdinal, item: MarketingCampaign) => MarketingCampaignOrdinal[value]
                },
                {
                    type: 'control', width: 150, headerTemplate: () => 'Actions', itemTemplate: (value: void, item: MarketingCampaign) => {
                        const $mainNode = $('<div>').addClass('js-grid-actions');
                        const $viewNode = $('<span>').addClass('sprite d-inline-block sprite-view').attr('title', 'Preview').appendTo($mainNode);
                        const $editNode = $('<span>').addClass('sprite d-inline-block sprite-edit').attr('title', 'Edit').appendTo($mainNode);
                        const $deleteNode = $('<span>').addClass('sprite d-inline-block sprite-delete').attr('title', 'Delete').appendTo($mainNode);
                        const $importNode = $('<span>').addClass('sprite d-inline-block sprite-import').attr('title', 'Import marketing campaign users').appendTo($mainNode);

                        if (!item.IsActive) {
                            $deleteNode.click(() => {
                                const $deleteDialog = $('#deleteMarketingCampaignDialog');

                                $deleteDialog.dialog({
                                    width: 500,
                                    modal: true,
                                    resizable: false,
                                    draggable: false,
                                    title: 'Delete Campaign',
                                    buttons: [{
                                        text: 'Cancel',
                                        click: () => {
                                            $deleteDialog.dialog('close');
                                        }
                                    }, {
                                        text: 'Delete',
                                        click: async () => {
                                            await this.productInformationApiService.marketingCampaignDeleteMarketingCampaign({ marketingCampaignId: item.MarketingCampaignId });

                                            $deleteDialog.dialog('close');
                                            await this.loadGridData();
                                        }
                                    }]
                                });
                            });
                        } else {
                            $deleteNode.addClass('disabled');
                        }

                        $editNode.click(() => {
                            const $editDialog = $('#addEditMarketingCampaignDialog');

                            $editDialog.html(addEditMarketingCampaignDialogTemplate({
                                MarketingCampaignId: item.MarketingCampaignId,
                                CampaignType: item.CampaignType,
                                InAppPlacement: item.InAppPlacement,
                                Name: item.Name,
                                ActiveFrom: item.ActiveFrom,
                                ActiveTo: item.ActiveTo,
                                DisplayOnLaunch: item.DisplayOnLaunch ? 'checked' : null,
                                HubsFilter: this.hubCodeList.map(cl => ({
                                    HubId: cl.Id,
                                    Name: cl.Name,
                                    Selected: item.Hubs.some(hub => hub.HubId == cl.Id) ? 'selected' : null
                                })),
                                UserLicenseOptions: item.UserLicenseOptions,
                                Ordinal: item.Ordinal,
                                ForNewRegisteredUser: item.ForNewRegisteredUser ? 'checked' : null,
                                Emails: item.Emails?.map(em => ({
                                    Email: em.Email
                                }))
                            }));

                            $editDialog.find('#active-from-date').datepicker({
                                dateFormat: 'yy-mm-dd',
                                minDate: new Date(1970, 0, 1)
                            });

                            $editDialog.find('#active-to-date').datepicker({
                                dateFormat: 'yy-mm-dd',
                                minDate: new Date(1970, 0, 1)
                            });

                            const userLicenseOptionsValues = Object.keys(UserLicenseOptions).filter((userLicenseOption) => {
                                return (Number(userLicenseOption) & item.UserLicenseOptions) == Number(userLicenseOption);
                            });

                            $('#addEditMarketingCampaignUserLicenseOptions').val(userLicenseOptionsValues);

                            forEach(item.PostalCodes, (item: string) => {
                                this.marketingCampaignHelper.addPostalCode(item);
                            });

                            $editDialog.find('#addEditMarketingCampaignOrdinal').val(item.Ordinal.toString());

                            if (item.Emails != null && item.Emails.length > 0) {
                                $editDialog.find('#addEditMarketingCampaignEmailControl').show();
                                const marketingCampaignEmailsList = $editDialog.find('#addEditMarketingCampaignEmailsList');
                                for (let i = 0; i < item.Emails.length; i++) {
                                    marketingCampaignEmailsList.append(addEditMarketingCampaignEmailTemplate({
                                        Email: item.Emails[i].Email
                                    }));
                                }
                            } else {
                                $editDialog.find('#addEditMarketingCampaignEmailControl').hide();
                            }

                            for (let i = 0; i < item.Hubs.length; i++) {
                                const hubCl = this.hubCodeList.find(hub => hub.Id == item.Hubs[i].HubId);

                                const regions: MarketingCampaignRegion[] = [];
                                for (let j = 0; j < hubCl.Regions.length; j++) {
                                    const regionCl = hubCl.Regions[j]
                                    const regionlanguagesLinks: MarketingCampaignRegionLanguage[] = [];
                                    const regionlanguagesCl = this.regionLanguageCodeList.filter(regionLanguage => regionLanguage.RegionId == regionCl.Id);
                                    const region = item.Hubs[i].Regions.find(region => region.RegionId == regionCl.Id);
                                    if (region?.RegionLanguages.length > 1) {
                                        region.RegionLanguages.forEach(language => {
                                            regionlanguagesLinks.push({
                                                HubId: i,
                                                Index: j,
                                                RegionId: regionCl.Id,
                                                LcId: language.LcId,
                                                RegionLanguage: regionlanguagesCl.find(x => x.RegionId == regionCl.Id && x.LcId == language.LcId).Name,
                                                Url: language.Url ? language.Url : null,
                                                Link: language ? language.Link : null,
                                                Enabled: language && language.Enabled ? 'checked' : null,
                                                Title: language ? language.Title : null,
                                                Description: language ? language.Description : null,
                                                InAppPlacement : item.InAppPlacement
                                            })
                                        })
                                    }

                                    regions.push({
                                        Id: regionCl.Id,
                                        Name: regionCl.Name,
                                        HubId: regionCl.HubId,
                                        RegionLanguages: regionlanguagesLinks,
                                        Index: i,
                                        Url: region ? region.Url : null,
                                        Link: region ? region.Link : null,
                                        Enabled: region && region.Enabled ? 'checked' : null,
                                        Title: region ? region.Title : null,
                                        Description: region ? region.Description : null,
                                        InAppPlacement : item.InAppPlacement
                                    })
                                }

                                $editDialog.find('#addEditMarketingCampaignHubs').append(addEditMarketingCampaignHubTemplate({
                                    Id: hubCl.Id,
                                    Name: hubCl.Name,
                                    Index: i,
                                    Regions: regions
                                }));
                            }

                            $editDialog.dialog({
                                width: 1032,
                                height: 800,
                                modal: true,
                                resizable: false,
                                draggable: false,
                                title: 'Edit ' + MarketingCampaignType[item.CampaignType] + ' Campaign',
                                buttons: [{
                                    text: 'Cancel',
                                    click: () => {
                                        $editDialog.dialog('close');
                                    }
                                }, {
                                    text: 'Save',
                                    click: () => {
                                        $editDialog.find('#addEditMarketingCampaignSubmit').click(); // needed to validate form
                                    }
                                }],
                                close: () => {
                                    $editDialog.empty();
                                },
                                open: () => {
                                    $editDialog.find('#addEditMarketingCampaignHubsFilter').chosen({ width: '100%' }).on('change', (eventObject, args) => this.hubsFilterChange(eventObject, args));
                                    $editDialog.find('#addEditMarketingCampaignUserLicenseOptions').chosen({ width: '100%' });

                                    $editDialog.find('#addEditMarketingCampaignPostalCodeInput').on('keypress', (e) => {
                                        if (e.key == 'Enter') {
                                            const input = $editDialog.find('#addEditMarketingCampaignPostalCodeInput').val();
                                            const parsedInput = this.marketingCampaignHelper.parsePostalCodeInput(input);

                                            forEach(parsedInput, item => this.marketingCampaignHelper.addPostalCode(item));

                                            e.preventDefault();
                                        }
                                    });

                                    $editDialog.find('#addEditMarketingCampaignForm').on('submit', () => {
                                        const formData = $editDialog.find('#addEditMarketingCampaignForm');

                                        const formJSONData = this.toJson(formData);
                                        const userLicenseOptionsValues = $editDialog.find('#addEditMarketingCampaignUserLicenseOptions').val() as string[];
                                        let setUserLicenseOptions = sumBy(userLicenseOptionsValues?.map(x => parseFloat(x) || 0) ?? [0]);
                                        // set to null if no option is chosen
                                        setUserLicenseOptions = setUserLicenseOptions == 0 ? null : setUserLicenseOptions;
                                        formJSONData['UserLicenseOptions'] = setUserLicenseOptions;

                                        this.productInformationApiService.marketingCampaignUpdateMarketingCampaign(formJSONData)
                                            .then(async () => {
                                                $editDialog.dialog('close');
                                                await this.loadGridData();
                                            });

                                        return false;
                                    });
                                }
                            });
                        });

                        $viewNode.click(() => {
                            const viewDialog = $('#viewMarketingCampaignDialog');

                            const regionLinks: UrlPreview[] = [];
                            item.Hubs.flatMap(hub => hub.Regions)
                                .forEach(region => {
                                    const regionName = this.hubRegionCodeList.find(cl => cl.Id == region.RegionId)?.Name;
                                    if (region.Enabled && regionName) {
                                        regionLinks.push({
                                            Name: regionName + " (default)",
                                            Url: region.Url
                                        });
                                    }
                                    region.RegionLanguages?.forEach(regionLanguage => {
                                        if (regionLanguage.Enabled) {
                                            const languageName = this.regionLanguageCodeList.find(cl => cl.LcId == regionLanguage.LcId)?.Name;
                                            if (languageName) {
                                                regionLinks.push({
                                                    Name: regionName + " (" + languageName + ")",
                                                    Url: regionLanguage.Url
                                                });
                                            }
                                        }
                                    })
                                });

                            viewDialog.html(viewMarketingCampaignDialogTemplate({
                                RegionSelect: regionLinks.map(region => ({
                                    Name: region.Name,
                                    Url: region.Url
                                }))
                            }));

                            viewDialog.dialog({
                                width: 550,
                                height: 550,
                                modal: true,
                                resizable: false,
                                draggable: false,
                                title: 'Preview ' + MarketingCampaignType[item.CampaignType] + ' Campaign',
                                buttons: [{
                                    text: 'Close',
                                    click: () => {
                                        viewDialog.dialog('close');
                                    }
                                }],
                                close: () => {
                                    viewDialog.empty();
                                },
                                open: () => {
                                    viewDialog.find('#viewMarketingCampaignRegionSelect').on('change', (event: JQueryEventObject) => {
                                        const element = <HTMLSelectElement>event.target;
                                        viewDialog.find('#viewMarketingCampaignPreview').empty();

                                        if (element.value != null && element.value != '')
                                            viewDialog.find('#viewMarketingCampaignPreview').html('<iframe src="' + element.value + '" allowfullscreen frameborder="0"></iframe>');
                                    });
                                }
                            });
                        });

                        $importNode.click(() => {
                            const compiledHtml = importMarketingCampaignEmailsTemplate({
                                MarketingCampaignId: item.MarketingCampaignId
                            });

                            this.$importMarketingCampaignEmailsExcelFile.html(compiledHtml);

                            this.$importMarketingCampaignEmailsExcelFile.dialog({
                                title: 'Import marketing campaign user emails',
                                width: '700px',
                                modal: true,
                                resizable: false,
                                draggable: false,
                                buttons: [
                                    {
                                        text: 'Cancel',
                                        click: () => {
                                            this.$importMarketingCampaignEmailsExcelFile.dialog('close');
                                        }
                                    },
                                    {
                                        text: 'Import',
                                        click: () => {
                                            const $form = this.$importMarketingCampaignEmailsExcelFile.find('form');
                                            const form = $form[0];
                                            const formData = new FormData(form);

                                            if (form.reportValidity == null || form.reportValidity()) {

                                                const file = (<HTMLInputElement>form.elements[1]).files[0];
                                                const marketingCampaignId = (<HTMLInputElement>form.elements[0]).value;

                                                const reader = new FileReader();
                                                reader.onload = async (event) => {
                                                    const arrayBuffer = event.target.result as ArrayBuffer;
                                                    const array = new Uint8Array(arrayBuffer);

                                                    const content = btoa(String.fromCharCode.apply(null, array as any));

                                                    const data = {
                                                        MarketingCampaignId: parseInt(marketingCampaignId),
                                                        Content: content
                                                    };

                                                    await this.productInformationApiService.marketingCampaignImportMarketingCampaignEmails(data);

                                                    this.$importMarketingCampaignEmailsExcelFile.dialog('close');
                                                    this.modalService.showMessage('Emails imported successfully', 'Import marketing campaign emails');
                                                    this.loadGridData();
                                                }
                                                reader.readAsArrayBuffer(file);
                                            }
                                        }
                                    }],
                                close: () => {
                                    this.$importMarketingCampaignEmailsExcelFile.empty();
                                }
                            });
                        });

                        return $mainNode;
                    }
                }
            ]
        });
    }

    private async loadData() {
        this.hubRegionCodeList = this.codeListsService.regions.map<HubRegion>(x => ({
            Id: x.Id,
            Name: x.Name,
            HubId: x.HubId
        }));

        this.hubCodeList = this.codeListsService.hubs.map<Hub>(x => ({
            Id: x.Id,
            Name: x.Name,
            Regions: this.hubRegionCodeList.filter(y => y.HubId == x.Id)
        }));

        this.regionLanguageCodeList = this.codeListsService.regionLanguages.map(x => ({
            Id: x.Id,
            Name: x.Name,
            LcId: x.LcId,
            RegionId: x.RegionId,
            DefaultForRegion: x.DefaultForRegion
        } as RegionLanguage));

        const $addDialog = $('#searchMarketingCampaignsForm');

        $addDialog.html(searchMarketingCampaignsFormTemplate({
            Hubs: this.hubCodeList,
            Regions: this.hubRegionCodeList
        }));

        this.initSearchForm();
        await this.loadGridData();
    }

    private async loadGridData() {
        const formDataArr = $('#marketingCampaignSearchForm').serializeArray();

        const formDataObj = {};
        let userLicenseOptions = 0;

        for (const field of formDataArr) {
            if (field.name == 'userLicenseOptions') {
                userLicenseOptions += parseInt(field.value);
            }
            else {
                formDataObj[field.name] = field.value;
            }
        }

        if (userLicenseOptions == 0) {
            userLicenseOptions = '' as any;
        }

        formDataObj['userLicenseOptions'] = userLicenseOptions;

        const marketingCampaignListMarketingCampaignsResponse = await this.productInformationApiService.marketingCampaignListMarketingCampaigns(formDataObj as any);

        const marketingCampaigns = marketingCampaignListMarketingCampaignsResponse.marketingCampaigns.map(item => {
            item.ActiveFrom = this.formatDateForInput(item.ActiveFrom);
            item.ActiveTo = this.formatDateForInput(item.ActiveTo);

            return item;
        });

        $('#marketingCampaignGrid').jsGrid({
            data: marketingCampaigns
        });
    }

    private askQuestion(question: string, title: string) {
        const questionDialog = $('<div hidden>').html(question).dialog({
            width: 500,
            modal: true,
            resizable: false,
            draggable: false,
            appendTo: 'body',
            title: title,
            buttons: [
                {
                    text: 'Yes',
                    click: () => {
                        questionDialog.dialog('close');
                        return true;
                    }
                },
                {
                    text: 'No',
                    click: () => {
                        questionDialog.dialog('close');
                        return false;
                    }
                }
            ],
            close: () => {
                questionDialog.dialog('destroy').remove();
                return false;
            }
        });
    }


    private formatDateForInput(jsonDate: string) {
        // gets the js date object from microsoft json format
        const jsDate = new Date(Date.parse(jsonDate));
        // add leading zeroes to js date when days or months are < 10.
        return jsDate.getUTCFullYear() + '-' +
            ((jsDate.getUTCMonth() + 1) < 10 ? ('0' + (jsDate.getUTCMonth() + 1)) : (jsDate.getUTCMonth() + 1)) + '-' +
            (jsDate.getUTCDate() < 10 ? ('0' + jsDate.getUTCDate()) : jsDate.getUTCDate());
    }

    private hubsFilterChange(eventObject: JQuery.TriggeredEvent<any, any, any, any>, args: Chosen.SelectedData) {
        if ("selected" in args) {
            // if new hub was selected in filter, add hub node to make hub editable
            const hubCl = this.hubCodeList.find(hub => hub.Id.toString() == args.selected);

            const numberOfChosenHubs = document.getElementById('addEditMarketingCampaignHubsFilter_chosen').getElementsByClassName('search-choice').length;

            const regions: MarketingCampaignRegion[] = [];
            for (let i = 0; i < hubCl.Regions.length; i++) {
                const regionCl = hubCl.Regions[i]
                const regionlanguagesLinks: MarketingCampaignRegionLanguage[] = [];
                const regionlanguagesCl = this.regionLanguageCodeList.filter(regionLanguage => regionLanguage.RegionId == regionCl.Id);
                if (regionlanguagesCl.length > 1) {
                    regionlanguagesCl.forEach(language => {
                        regionlanguagesLinks.push({
                            HubId: numberOfChosenHubs - 1,
                            Index: i,
                            RegionId: regionCl.Id,
                            LcId: language.LcId,
                            RegionLanguage: language.Name,
                            Url: null,
                            Link: null,
                            Enabled: null,
                            Title: null,
                            Description: null,
                            InAppPlacement : this.inAppPlacement
                        });
                    })
                }

                regions.push({
                    Id: regionCl.Id,
                    Name: regionCl.Name,
                    HubId: regionCl.HubId,
                    RegionLanguages: regionlanguagesLinks,
                    Index: numberOfChosenHubs - 1,
                    Url: null,
                    Link: null,
                    Enabled: null,
                    Title: null,
                    Description: null,
                    InAppPlacement : this.inAppPlacement
                });
            }

            $('#addEditMarketingCampaignDialog').find('#addEditMarketingCampaignHubs').append(addEditMarketingCampaignHubTemplate({
                Id: hubCl.Id,
                Name: hubCl.Name,
                Index: numberOfChosenHubs - 1,
                Regions: regions
            }));
        }
        else if (args.deselected != null) {
            // hub was removed from filter, remove it also from editable hubs
            $('#addEditMarketingCampaignDialog').find('#addEditMarketingCampaignHub-' + args.deselected).remove();
        }
    }

    private toJson(dom: HTMLElement | JQuery): any {
        const $dom = $(dom);
        const $inputs = $dom.find('input, select, textarea');

        const json = {};

        $inputs.each((index, element: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement) => {
            const name = element.name;
            if (name) {
                let value: string | boolean | number = element.value.trim();

                if (!isNaN(Number(value)) && !name.includes("PostalCode")) {
                    value = Number(value);
                }

                if (element.type == 'checkbox') {
                    value = (element as HTMLInputElement).checked;
                }

                if (element.type == 'url' && value == '') {
                    value = null;
                }

                if (element.type == 'text' && value == '0') {
                    value = null;
                }

                if (element.type == 'hidden' && ['true', 'false'].includes(value?.toString())) {
                    value = value === 'true';
                }

                set(json, name, value);
            }
        });

        // remove null values from arrays
        Object.entries(json).forEach(([key, value]) => json[key] = Array.isArray(value) ? value.filter((x: any) => x) : value);
        return json;
    }

    private initSearchForm() {
        $('#marketingCampaignSearchButton').click(async () => {
            await this.loadGridData();

            // Since search probably results in less entries go back to page 1
            $('#marketingCampaignGrid').jsGrid({
                pageIndex: 1
            });
        });

        $('#marketingCampaignClearSearchButton').click(async () => {
            const $form = $('#marketingCampaignSearchForm');
            const form = <HTMLFormElement>$form[0];

            form.reset();
            $('#marketingCampaignSearchHub').trigger('chosen:updated');
            $('#marketingCampaignSearchRegion').trigger('chosen:updated');
            $('#marketingCampaignSearchUserLicenseOptions').trigger('chosen:updated');

            await this.loadGridData();
        });

        $('#marketingCampaignSearchHub').chosen();
        $('#marketingCampaignSearchRegion').chosen();
        $('#marketingCampaignSearchUserLicenseOptions').chosen();

        $('#marketingCampaignAddButton').click(() => {
            const $chooseTypeDialog = $('#chooseTypeMarketingCampaignDialog');

            $chooseTypeDialog.dialog({
                width: 500,
                modal: true,
                resizable: false,
                draggable: false,
                title: 'Add New Campaign',
                buttons: [{
                    text: 'Cancel',
                    click: () => {
                        $chooseTypeDialog.dialog('close');
                    }
                },
                {
                    text: 'Next',
                    click: () => {
                        const data = $chooseTypeDialog.find('#chooseTypeMarketingCampaignForm').serializeArray();
                        const campaignType = parseInt(data.find(e => e.name === 'CampaignType')?.value, 10);
                        const inAppPlacement = data.some(e => e.name === 'InAppPlacement');
                        this.inAppPlacement = inAppPlacement;

                        $chooseTypeDialog.dialog('close');

                        if (campaignType != null) {
                            const $addDialog = $('#addEditMarketingCampaignDialog');

                            $addDialog.html(addEditMarketingCampaignDialogTemplate({
                                CampaignType: campaignType,
                                InAppPlacement: inAppPlacement,
                                HubsFilter: this.hubCodeList.map(hub => ({
                                    HubId: hub.Id,
                                    Name: hub.Name
                                }))
                            }));

                            $addDialog.find('#active-from-date').datepicker({
                                dateFormat: 'yy-mm-dd',
                                minDate: new Date(1970, 0, 1)
                            });

                            $addDialog.find('#active-to-date').datepicker({
                                dateFormat: 'yy-mm-dd',
                                minDate: new Date(1970, 0, 1)
                            });

                            $addDialog.find('#addEditMarketingCampaignEmailControl').hide();

                            $addDialog.dialog({
                                width: 1032,
                                height: 800,
                                modal: true,
                                resizable: false,
                                draggable: false,
                                title: 'Add New ' + MarketingCampaignType[campaignType] + ' Campaign',
                                buttons: [{
                                    text: 'Cancel',
                                    click: () => {
                                        $addDialog.dialog('close');
                                    }
                                }, {
                                    text: 'Save',
                                    click: () => {
                                        $addDialog.find('#addEditMarketingCampaignSubmit').click(); // needed to validate form
                                    }
                                }],
                                close: () => {
                                    $addDialog.empty();
                                },
                                open: () => {
                                    $addDialog.find('#addEditMarketingCampaignHubsFilter').chosen({ width: '100%' }).on('change', (eventObject, args) => this.hubsFilterChange(eventObject, args));
                                    $addDialog.find('#addEditMarketingCampaignUserLicenseOptions').chosen({ width: '100%' });

                                    $addDialog.find('#addEditMarketingCampaignPostalCodeInput').on('keypress', (e) => {
                                        if (e.key == 'Enter') {
                                            const input = $addDialog.find('#addEditMarketingCampaignPostalCodeInput').val();
                                            const parsedInput = this.marketingCampaignHelper.parsePostalCodeInput(input);

                                            forEach(parsedInput, item => this.marketingCampaignHelper.addPostalCode(item));

                                            e.preventDefault();
                                        }
                                    });

                                    $addDialog.find('#addEditMarketingCampaignForm').on('submit', () => {
                                        const $formData = $addDialog.find('#addEditMarketingCampaignForm');

                                        const formJSONData = this.toJson($formData);
                                        const userLicenseOptionsValues = $addDialog.find('#addEditMarketingCampaignUserLicenseOptions').val() as string[];
                                        let setUserLicenseOptions = sumBy(userLicenseOptionsValues?.map(x => parseFloat(x) || 0) ?? [0]);
                                        // set to null if no option is chosen
                                        setUserLicenseOptions = setUserLicenseOptions == 0 ? null : setUserLicenseOptions;
                                        formJSONData['UserLicenseOptions'] = setUserLicenseOptions;

                                        this.productInformationApiService.marketingCampaignAddMarketingCampaign(formJSONData)
                                            .then(async () => {
                                                $addDialog.dialog('close');
                                                await this.loadGridData();
                                            });

                                        return false;
                                    });
                                }
                            });
                        }
                    }
                }],
                close: () => {
                    const $form = $chooseTypeDialog.find('#chooseTypeMarketingCampaignForm');
                    const form = <HTMLFormElement>$form[0];

                    form.reset();
                },
            });
        });
    }
}
